import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/Seo';
import Gallery from '../components/Gallery';

const PhotoGallery = () => (
  <Layout>
    <SEO title='Photo Gallery' description='Photo gallery of React Academy' />
    <Gallery />
  </Layout>
);

export default PhotoGallery;
