import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  // Carousel,
  // CarouselIndicators,
  // CarouselControl,

  // CarouselItem,
  // CarouselCaption,
} from 'reactstrap';

import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';

//import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ItemsCarousel from 'react-items-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
//import {} from '@fortawesome/free-regular-svg-icons';

// class Carousel extends OCarousel {
//   static getDerivedStateFromProps(nextProps, _ref) {
//     console.log('WOW!!!!');
//     var previousActiveIndex = _ref.activeIndex;

//     if (nextProps.activeIndex !== previousActiveIndex) {
//       var lastPossibleIndex = nextProps.children.length - 1;
//       var nextIndex = Math.max(
//         0,
//         Math.min(nextProps.activeIndex, lastPossibleIndex)
//       );
//       var direction;

//       if (
//         (nextIndex === 0 && previousActiveIndex >= lastPossibleIndex) ||
//         previousActiveIndex <= nextIndex
//       ) {
//         direction = 'next';
//       } else {
//         direction = 'previous';
//       }

//       return {
//         direction: direction,
//         previousActiveIndex: previousActiveIndex,
//         activeIndex: nextIndex,
//       };
//     }

//     return null;
//   }
// }

const Gallery = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allGalleryJson {
          nodes {
            date
            description
            id
            src {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const pictures = data.allGalleryJson.nodes;

  const [animating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isModalOpen, setModalOpen] = useState(false);

  const { locale } = useLocalization();
  const lang = locale === 'ca' ? 'en' : locale;
  const { t } = useTranslation();

  const dtf = new Intl.DateTimeFormat(lang + '-CA', {
    year: 'numeric',
    month: 'long',
  });

  function close() {
    setModalOpen(false);
  }

  function nextPic(evt) {
    if (animating) return;
    const nextIndex = activeIndex === pictures.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  function previousPic(evt) {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? pictures.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  function goToIndex(newIndex) {
    setActiveIndex(newIndex);
  }

  return (
    <section className='bg-dark py-4' style={{ marginTop: '60px' }}>
      <h2 className='text-center display-4 mb-4 text-light'>
        {t('photo-gallery')}
      </h2>

      <ItemsCarousel
        // Carousel configurations
        numberOfCards={1.8}
        gutter={25}
        infiniteLoop={true}
        showSlither={true}
        firstAndLastGutter={true}
        freeScrolling={true}
        // Active item configurations
        requestToChangeActive={goToIndex}
        activeItemIndex={activeIndex}
        activePosition={'center'}
        chevronWidth={100}
        rightChevron={
          <Button aria-label='next' size='lg'>
            <span className='sr-only'>Next</span>
            <FontAwesomeIcon icon={faArrowRight} size='2x' />
          </Button>
        }
        leftChevron={
          <Button arial-label='previous' size='lg'>
            <span className='sr-only'>Previous</span>
            <FontAwesomeIcon icon={faArrowLeft} size='2x' />
          </Button>
        }
        outsideChevron={false}
        classes={{
          itemsWrapper: 'disable-touch-back',
          itemWrapper: 'my-auto',
        }}
      >
        {pictures.map((pic) => (
          <a
            key={pic.id}
            href='#pic'
            onClick={() => {
              const idx = pictures.findIndex((p) => p === pic);
              setActiveIndex(idx);
              setModalOpen(true);
            }}
          >
            <Img
              fluid={pic.src.childImageSharp.fluid}
              alt={pic.description}
              title={pic.description}
              style={{ cursor: 'pointer', borderRadius: '15px' }}
            />
          </a>
        ))}
      </ItemsCarousel>

      {activeIndex !== -1 && (
        <Modal isOpen={isModalOpen} toggle={close} size='xl'>
          <ModalHeader toggle={close}>
            {pictures[activeIndex] && pictures[activeIndex].description}
          </ModalHeader>
          <ModalBody>
            <Carousel activeIndex={activeIndex} onSelect={goToIndex}>
              {pictures.map((pic) => (
                <Carousel.Item key={pic.id}>
                  <Img
                    fluid={pic.src.childImageSharp.fluid}
                    alt={pic.description}
                    title={pic.description}
                  />
                  <Carousel.Caption>
                    <h3>{dtf.format(new Date(pic.date))}</h3>
                    <p>{pic.description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </ModalBody>
          <ModalFooter>
            <p style={{ width: '100%' }}>
              {pictures[activeIndex] &&
                dtf.format(new Date(pictures[activeIndex].date))}
            </p>
            <Button className='mx-3' onClick={close}>
              {t('close')}
            </Button>
            <Button className='mr-3' onClick={previousPic}>
              {t('previous')}
            </Button>
            <Button onClick={nextPic}>{t('next')}</Button>
          </ModalFooter>
        </Modal>
      )}
    </section>
  );
};

export default Gallery;
